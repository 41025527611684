<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'customers' }">
          Customers
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="form">
      <div class="display-2 font-weight-bold my-6">
        {{ form.email }}
      </div>

      <div class="my-6 row">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <v-form ref="form" v-if="form" style="max-width: 400px">
            <label class="text-field-label">MyID</label>
            <v-text-field
              flat
              solo
              required
              readonly
              class="mt-2"
              v-model="form.hash_id"
              :error-messages="form.$getError('hash_id')"
            ></v-text-field>

            <label class="text-field-label">First Name</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              v-model="form.first_name"
              :error-messages="form.$getError('first_name')"
            ></v-text-field>

            <label class="text-field-label">Last Name</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              v-model="form.last_name"
              :error-messages="form.$getError('last_name')"
            ></v-text-field>

            <label class="text-field-label"
              >Current Address : <br />{{ form.address }}</label
            >
            <v-autocomplete
              v-model="new_address"
              :items="itemsData"
              :loading="isLoading"
              :search-input.sync="search"
              color="white"
              hide-no-data
              hide-selected
              item-text="Description"
              item-value="API"
              placeholder="Update current address"
              label=""
              class="mt-2"
              flat
              solo
              return-object
              :error-messages="form.$getError('address')"
            ></v-autocomplete>

            <div class="mt-4">
              <v-btn
                color="primary"
                depressed
                class="mr-4 px-6"
                height="40px"
                @click="updateCustomerDetails"
                :loading="form.$busy"
                >Update</v-btn
              >
              <v-btn
                color="red"
                dark
                depressed
                class="mr-4 px-6"
                height="40px"
                @click="deleteDialog = true"
                :loading="form.$busy"
                >Delete</v-btn
              >
            </div>
          </v-form>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12">
          <v-form class="" ref="form" v-if="form" style="max-width: 400px">
            <label class="text-field-label">GreenID Ref No.</label>
            <v-text-field
              class="mt-2"
              flat
              solo
              v-model="form.green_verification_id"
              readonly
            ></v-text-field>

            <label class="text-field-label">GreenID Status</label>
            <v-flex class="mt-2">
              <v-select
                :items="items"
                v-model="form.green_overall_verification_status"
                label=""
                flat
                solo
              ></v-select>
            </v-flex>
            <v-btn
              color="primary"
              depressed
              class="mr-4 px-6"
              height="40px"
              @click="statusDialog = true"
              :loading="form.$busy"
              >Update Status</v-btn
            >
          </v-form>
        </div>
        <v-dialog v-model="statusDialog" max-width="290">
          <v-card>
            <v-card-title class="headline">Update ?</v-card-title>

            <v-card-text
              >Are you sure you want to update the greenID status ?</v-card-text
            >
            <v-card-text
              >Note : Updating the status of greenID will not override what is
              already set on the greenID admin panel. You can also login to your
              green ID admin panel and manually update the customer status.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="deleteDialog = false" :loading="form.$busy"
                >Cancel</v-btn
              >

              <v-btn
                color="red"
                text
                @click="updateGreenStatus"
                :loading="form.$busy"
                >Yes, Update please</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="290">
          <v-card>
            <v-card-title class="headline">Delete ?</v-card-title>

            <v-card-text
              >Are you sure you want to delete this account ?</v-card-text
            >

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="deleteDialog = false" :loading="form.$busy"
                >Cancel</v-btn
              >

              <v-btn
                color="red"
                text
                @click="deleteCustomerData"
                :loading="form.$busy"
                >Yes, Delete please</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

import Form from '@/utils/form'

export default {
  name: 'CustomerDetails',

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
      loading: false,
      deleteDialog: false,
      statusDialog: false,
      form: null,
      showCropperModal: false,
      tabItems: [{ tab: 'Info' }],
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
      items: ['VERIFIED', 'IN_PROGRESS'],
      add: [],
      isLoading: false,
      search: null,
      new_address: [],
      entries: [],
    }
  },

  computed: {
    ...mapState({
      customer: (state) => state.customer.customerDetails,
      authUser: (state) => state.auth.user,
    }),
    ...mapGetters('customer', ['addressItems']),
    itemsData() {
      return this.entries.map((entry) => {
        const Description = entry.fullAddress

        return Object.assign({}, entry, { Description })
      })
    },
  },

  methods: {
    ...mapActions({
      getCustomerDetails: 'customer/getCustomerDetails',
      updateCustomer: 'customer/updateCustomer',
      deleteCustomer: 'customer/deleteCustomer',
      updateGreenID: 'customer/updateGreenIdStatus',
      validateAddress: 'customer/checkAddress',
    }),

    ...mapMutations({
      clearCustomerDetails: 'customer/clearCustomerDetails',
    }),

    async getCustomer() {
      this.loading = true
      await this.getCustomerDetails(this.$route.params.id)
      this.form = new Form(this.customer)
      this.loading = false
    },

    async updateGreenStatus() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateGreenID(this.form.$data())
        .then(() => {
          this.form.$busy = false
          this.statusDialog = false
          this.showSnackbar('Customer status successfully updated!', 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    async updateCustomerDetails() {
      this.form.$busy = true
      this.form.$clearErrors()

      if (this.new_address) {
        this.form.update_address = this.newAddress()
      }

      this.updateCustomer(this.form)
        .then(() => {
          this.form.$busy = false
          this.statusDialog = false
          this.new_address = []
          this.getCustomer()
          this.showSnackbar('Customer details successfully updated!', 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    newAddress() {
      const address = {
        full_address: this.new_address.fullAddress,
        floor_or_unit_number: this.new_address.floorLevelNumber,
        street_number: this.new_address.streetNumber,
        street_type: this.new_address.streetType,
        street_name: this.new_address.streetName,
        suburb: this.new_address.locality,
        state: this.new_address.state,
        postcode: this.new_address.postcode,
        longitude: this.new_address.attributes
          ? this.new_address.attributes.Longitude
          : '',
        latitude: this.new_address.attributes
          ? this.new_address.attributes.Latitude
          : '',
      }
      return address
    },

    async deleteCustomerData() {
      this.form.$busy = true
      this.deleteCustomer(this.form.$data())
        .then(() => {
          this.form.$busy = false
          this.deleteDialog = false
          this.showSnackbar('Customer deleted successfully!')
          this.$router.replace('/customers')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },

  created() {
    this.getCustomer()
  },

  destroyed() {
    this.clearCustomerDetails()
  },

  watch: {
    $route() {
      this.getCustomer()
    },
    search(val) {
      // // Items have already been loaded
      if (this.addressItems.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      this.validateAddress({
        full_address: val,
      })
        .then((res) => {
          this.entries = this.addressItems
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
